export const ZABO_CATEGORIES = [
  "행사",
  "공연",
  "축제",
  "세미나",
  "교육",
  "모임",
  "이벤트",
  "공모전",
  "전시",
  "공지",
  "모집",
  "채용",
  "봉사",
  "오픈동방",
  "데모데이",
];
export const GROUP_CATEGORIES = ["학생단체", "동아리", "학교 부서", "스타트업", "기업"];
export const GROUP_CATEGORIES_2 = [
  "과학생회",
  "자치단체",
  "총학생회",
  "생활문화",
  "예술",
  "음악",
  "종교사회",
  "체육",
  "학술",
  "창업",
];
/* eslint-disable */
export const GROUP_CATEGORY_HIERARCHIY = {
  학생단체: ["과학생회", "자치단체", "총학생회"],
  동아리: ["생활문화", "예술", "음악", "종교사회", "체육", "학술", "창업"],
};
export const RESERVED_ROUTES_USERNAME_EXCEPTIONS = ["auth", "settings", "admin"];

export const alerts = {
  login: "로그인이 필요합니다. SSO를 사용해 로그인 하시겠습니까?",
  upload: "자보를 게시하시겠습니까?",
  edit: "자보를 수정하시겠습니까?",
  del: "정말로 자보를 삭제하시겠습니까?",
  addMember: "멤버를 추가하시겠습니까?",
  updateMember: "멤버 권한을 변경하시겠습니까?",
  deleteMember: "정말로 멤버를 삭제하시겠습니까?",
};
