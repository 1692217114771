export const colors = {
  main: "#143441",
  point: "#ff5d5d",
  sub: "#35d48d",
  textMain: "#202020",
  gray100: "#202020",
  gray90: "#363636",
  gray80: "#4d4d4d",
  gray70: "#636363",
  gray60: "#797979",
  gray50: "#8f8f8f",
  gray40: "#a6a6a6",
  gray30: "#bcbcbc",
  gray20: "#d2d2d2",
  gray10: "#e9e9e9",
  gray5: "#f4f4f4",
  gray3: "#f8f8f8",
  gray2: "#fbfbfb",
  gray1: "#fdfdfd",
  cobalt: "#194386",
  black: "#333333",
  red: "#e60909",
  red50: "#FA6B6B",
  green50: "#35d48d",
  green: "#00a97d",
  pinkishGrey: "#cccccc",
  border: "#cccccc",
  pinkishGreyTwo: "#d0d0d0",
  deepBlue: "#194386",
  white: "white",
  teal: "#00a97d",
  tealishGreen: "#05d27e",
  azure: "#0090ff",
  brownishGrey: "#666666",
  warmGrey: "#999999",
  veryLightBlue: "#dee7f2",
  none: "rgba(0,0,0,0)",
} as const;
